<template>
  <div>
    <el-card class="margin-x">
      <el-page-header @back="goBack" :content="$t('练习记录')">
      </el-page-header>

      <div class="margin-lt margin-x">
        <el-form label-position="left" label-width="100px">
          <el-form-item :label="$t('选择题型') + ':'">
            <el-radio-group @change="query(1)" v-model="tag">
              <el-radio border size="medium" v-for="(item, index) in tags" :key="index" :label="item.tag"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('题目类型') + ':'">
            <el-radio-group @change="query(1)" v-model="type">
              <el-radio border size="medium" v-for="(item, index) in types" :key="index" :label="item"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-divider></el-divider>
      <div v-loading="loading">
        <el-empty v-if="list.length == 0"></el-empty>
        <div v-for="(item, index) in list" :key="index" @click="showRecord(item)"
          :class="{ 'bb': index < list.length - 1 }" class="mock-item on">
          <template>
            <div class="flex flex-between flex-v-center" style="height:100%">
              <div>
                <h5>{{ `${index + 1}. #${item.nos.join(',')}...` }}</h5>
                <div class="flex flex-v-center margin-t">
                  <div style="font-size: 12px;margin-left:8px;">{{ item.created_at }}</div>
                </div>
              </div>
              <div class="flex flex-v-center">
                <h5>【{{ $t(`${item.num}题`) }}】</h5>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </template>
        </div>

        <div class="center margin-t">
          <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

  </div>
</template>
<script>
export default {
  name: "immersive-list",
  data() {
    return {
      dialogVisible: false,
      loading: true,
      tag: '',
      type: '预测',
      page: 1,
      total: 0,
      types: ['预测', '机经', '全部'],
      list: [],
      records: [],
      loaded: false,
    };
  },

  watch: {
    tags: {
      handler(val) {
        if (val && val.length > 0) {
          this.tag = val[0].tag
          this.query()
        }
      },
      immediate: true
    },
   
  },


  computed: {
    tags: function () {
      return this.$store.state.types.filter((val) => {
        return val.is_immersive
      })
    },
  },



  methods: {
    showRecord(item) {
      this.$router.push({
        path: '/main/immersive/record',
        query: { id: item.id, tag: item.tag, type: item.type, num: item.num }
      })
    },
    goBack() {
      this.$router.go(-1);
    },

    pageChange(val) {
      this.page = val
      this.query()
    },
    query() {
      this.loading = true
      this.$http.post("/api/immersive/list", {
        page: this.page,
        tag: this.tag,
        type: this.type,
      }).then(res => {
        this.total = res.total
        this.list = res.data
        this.loading = false
      })
    },
  },
};
</script>

<style scope>
.mock-item {
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.mock-item.bb {
  margin-bottom: 15px;
}

.mock-item:hover,
.mock-item.on {
  border: 1px solid #e6a13c;
  color: #e6a13c;
}
</style>
